<template>
  <div v-show="step === 0">
    <div class="title">{{ $t("DELETE_ACCOUNT_INTRO_TITLE") }}</div>
    <ol class="instruction">
      <li>{{ $t("DELETE_ACCOUNT_STEP_1") }}</li>
      <li>{{ $t("DELETE_ACCOUNT_STEP_2") }}</li>
      <li>{{ $t("DELETE_ACCOUNT_STEP_3") }}</li>
      <li>{{ $t("DELETE_ACCOUNT_STEP_4") }}</li>
      <li>{{ $t("DELETE_ACCOUNT_STEP_5") }}</li>
      <li>{{ $t("DELETE_ACCOUNT_STEP_6") }}</li>
    </ol>
    <div class="enable-button" @click="nextStep(1)">
      {{ $t("PROCEED") }}
    </div>
  </div>
  <div v-show="step === 1">
    <div class="title">{{ $t("DELETE_ACCOUNT_IMPACT_TITLE") }}</div>
    <ul class="instruction">
      <li>{{ $t("DELETE_ACCOUNT_IMPACT_1") }}</li>
      <li>{{ $t("DELETE_ACCOUNT_IMPACT_2") }}</li>
      <li>{{ $t("DELETE_ACCOUNT_IMPACT_3") }}</li>
    </ul>
    <p class="instruction mb-15rem">{{ $t("DELETE_ACCOUNT_IMPACT_REMARK") }}</p>
    <div class="subtitle">{{ $t("WHY_DELETE_ACCOUNT") }}</div>
    <div id="SelectAReason" class="selected-reason-wrapper" @click="toggleReasonList">
      <span>{{ selectedReasonText }}</span>
      <span>
        <svg
          class="dropdown-select-arrow"
          :class="{ rotate: showReasonList }"
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.516782 1.33746C0.952782 0.802265 1.55978 0.760265 2.09278 1.33746L6.00078 5.83386L9.90878 1.33746C10.4418 0.760265 11.0498 0.802265 11.4828 1.33746C11.9188 1.87146 11.8908 2.77386 11.4828 3.27546C11.0768 3.77706 6.78778 8.67786 6.78778 8.67786C6.57078 8.94546 6.28578 9.07986 6.00078 9.07986C5.71578 9.07986 5.43078 8.94546 5.21178 8.67786C5.21178 8.67786 0.924782 3.77706 0.516782 3.27546C0.108782 2.77386 0.0807824 1.87146 0.516782 1.33746Z"
            fill="white"
          />
        </svg>
      </span>
    </div>
    <div class="selection-reason-container" v-show="showReasonList && !isMobile">
      <!-- <perfect-scrollbar class="perfect-scrollball__medium"> -->
        <div class="selection-reason-inner-container">
          <div
            class="selection-reason"
            :class="{ selected: selectedReasonId === o.key }"
            @click="userSelectAReason(o)"
            v-for="o in reasonList"
            :key="o.key"
          >
            <span>{{ $t(o.key) }}</span>
            <svg
              v-show="selectedReasonId === o.key"
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.85948 7.58678L1.07948 4.80678L0.132812 5.74678L3.85948 9.47345L11.8595 1.47345L10.9195 0.533447L3.85948 7.58678Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      <!-- </perfect-scrollbar> -->
    </div>
    <div v-show="selectedReasonId === 'DELETE_ACCOUNT_REASON_9'" class="other-reason-container">
      <div class="subtitle">{{ $t("WRITE_OPTIONAL_REASON") }}</div>
      <div class="form-group__container form-group__textarea-container">
        <perfect-scrollbar class="perfect-scrollball__medium">
          <textarea
            class="form-control textarea"
            rows="4"
            :placeholder="$t('WRITE_YOUR_REASON_HERE')"
            v-model="otherDeleteReason"
          ></textarea>
        </perfect-scrollbar>
      </div>
    </div>

    <div
      v-show="enableDeleteButton"
      class="enable-button"
      @click="openConfirmDeleteModal"
    >
      {{ $t("DELETE_MY_ACCOUNT") }}
    </div>
    <div
      v-show="!enableDeleteButton"
      class="disable-button"
    >
      {{ $t("DELETE_MY_ACCOUNT") }}
    </div>
  </div>

  <ModalPopup ref="modalPopupSubmitRef">
    <div class="display-flex-align-center flex-col modal-confirmation__body">
      <div class="mb-15rem font-m tc">
          {{ $t("SURE_TO_DELETE_ACCOUNT") }}
      </div>
      <div class="w-100">
          <button class="btn w-100 mb-1rem bg-white" @click="deleteUserAccount">
              {{ $t("CONFIRM") }}
          </button>
          <button class="btn btn-outline w-100" @click="closeConfirmDeleteModal">
              {{ $t("CANCEL") }}
          </button>
      </div>
    </div>
  </ModalPopup>

  <ModalPopup ref="modalPopupDeletedRef" :isShowCloseButton="false" @handleCloseEvent="closeDeletedModal">
    <div class="display-flex-align-center flex-col modal-confirmation__body">
    <div class="mb-15rem font-m tc">
        <div>{{ $t("ACCOUNT_DELETED_1") }}</div>
        <div>{{ $t("ACCOUNT_DELETED_2") }}</div>
    </div>
    <div class="w-100">
        <button class="btn w-100 mb-1rem bg-white" @click="closeDeletedModal">{{ $t('OK') }}</button>
    </div>
    </div>
  </ModalPopup>
</template>

<script>
import ModalPopup from "@/components/modal/ModalPopup.vue";
import { mapGetters, mapActions } from "vuex";
import MobileSelect from "@/lib/mobile-select/mobile-select";

const OTHER_REASON = "DELETE_ACCOUNT_REASON_9";

export default {
  components: {
    ModalPopup,
  },
  watch: {
    otherDeleteReason: {
      handler(newVal, oldVal) {
        // this.enableDeleteButton = newVal ? true : false;
        if (newVal.length >= 100)
          this.otherDeleteReason = newVal.substring(0, 100);
      }
    },
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "currentLocale",
      "isLogin",
      "userInfo",
      "currentLanguageObj",
    ]),
    selectedReasonText() {
      if (this.selectedReasonId === null)
        return this.$t("SELECT_A_REASON");
      else
        return this.$t(this.selectedReasonId);
    },
  },
  mounted() {
    if (this.isMobile)
      this.mobileSelector();
    // this.hintYScrollbar();
  },
  data() {
    return {
      step: 0,
      selectedReasonId: null,
      showReasonList: false,
      selectedReason: null,
      enableDeleteButton: false,
      otherDeleteReason: null,
      reasonList: [
        {
          key: "DELETE_ACCOUNT_REASON_1",
          value: "No longer using AsiaSport",
        },
        {
          key: "DELETE_ACCOUNT_REASON_2",
          value: "Too many permissions / Privacy concern",
        },
        {
          key: "DELETE_ACCOUNT_REASON_3",
          value: "High Storage Space",
        },
        {
          key: "DELETE_ACCOUNT_REASON_4",
          value: "A lot of crashes, errors or keeps freezing",
        },
        {
          key: "DELETE_ACCOUNT_REASON_5",
          value: "High data consumption",
        },
        {
          key: "DELETE_ACCOUNT_REASON_6",
          value: "Draining battery",
        },
        {
          key: "DELETE_ACCOUNT_REASON_7",
          value: "AsiaSport is not updating frequently",
        },
        {
          key: "DELETE_ACCOUNT_REASON_8",
          value: "Poor user interface",
        },
        {
          key: "DELETE_ACCOUNT_REASON_9",
          value: "Other reasons",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["postDeleteUserAccount"]),
    // hintYScrollbar() {
    //   this.$nextTick(() => {
    //     let yScrollbarSlot = document.querySelector(".ps__rail-y");
    //     let yScrollbar = document.querySelector(".ps__thumb-y");
    //     yScrollbarSlot.style.height = "100%";
    //     yScrollbarSlot.style.display = "block";
    //     yScrollbar.style.height = "100%";
    //     yScrollbar.style.display = "block";
    //   })
    // },
    mobileSelector() {
      if (this.isMobile) {
        let that = this;

        let triggerId = "#SelectAReason";
        let title = this.$i18n.t("SELECT_A_REASON");
        let ensureBtnText = this.$i18n.t("DONE");
        let cancelBtnText = this.$i18n.t("CANCEL");

        let ensureBtnColor = "#01c3f7";
        let titleBgColor = "#F0F0F0";
        let translatedReasonList = [];

        for(let i = 0; i < this.reasonList.length; i++) {
          translatedReasonList.push({
            key: this.reasonList[i].key,
            value: this.$t(this.reasonList[i].key),
          });
        }

        this.msInstance = new MobileSelect({
          trigger: triggerId,
          title: title,
          ensureBtnText: ensureBtnText,
          cancelBtnText: cancelBtnText,
          ensureBtnColor: ensureBtnColor,
          titleBgColor: titleBgColor,
          triggerDisplayData: false,
          wheels: [{ data: translatedReasonList }],

          callback: function(indexArr, data) {
            // this.periodSelected = data[0];
            that.userSelectAReason(data[0]);
          },
        });
      }
    },
    openConfirmDeleteModal() {
      this.$refs.modalPopupSubmitRef.handleOpen();
    },
    closeConfirmDeleteModal() {
      this.$refs.modalPopupSubmitRef.handleClose();
    },
    openDeletedModal() {
      this.$refs.modalPopupDeletedRef.handleOpen();
    },
    closeDeletedModal() {
      // this.$refs.modalPopupDeletedRef.handleClose();
      this.$router.replace({ name: "home" });
    },
    async deleteUserAccount() {
      let result;
      let params = {
        remark: this.selectedReason,
        token: this.userInfo.token,
      }
      if (this.selectedReasonId === OTHER_REASON && this.otherDeleteReason !== null)
        params.remark = this.$t(OTHER_REASON) + " - " + this.otherDeleteReason;
      result = await this.postDeleteUserAccount(params);
      if (result.message === "Success") {
        this.closeConfirmDeleteModal();
        this.openDeletedModal();
      }
    },
    nextStep(next) {
      this.step = next;
    },
    toggleReasonList() {
      this.showReasonList = !this.showReasonList;
      // this.hintYScrollbar();
    },
    userSelectAReason(o) {
      this.selectedReasonId = o.key;
      this.selectedReason = this.$t(o.key);//o.value
      this.toggleReasonList();
      if (this.selectedReasonId !== null)
        this.enableDeleteButton = true;
      else this.enableDeleteButton = false;
    },
  }
}
</script>

<style scoped>
.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
}
.instruction {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
ol {
  padding-left: 20px;
}
ul {
  padding: 16px 0 16px 30px;
}
ul li::marker {
  content: initial;
}
.subtitle {
  font-weight: 700;
  line-height: 13px;
  font-size: 11px;
}
.enable-button {
  background: var(--color-hover);
  color: #000000;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding: 10px 12px;
  border-radius: 4px;
  margin-top: 24px;
  cursor: pointer;
}
.disable-button {
  background: var(--color-grey-60);
  color: #5c7784;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding: 10px 12px;
  border-radius: 4px;
  margin-top: 24px;
  cursor: not-allowed;
}
.selected-reason-wrapper {
  display: flex;
  justify-content: space-between;
  background: var(--color-grey-60);
  color: var(--color-grey-light);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding: 8px;
  border-radius: 4px;
  margin: 3px 0 0px 0;
  position: relative;
  cursor: pointer;
}
.selection-reason-container {
  border-radius: 8px;
  background: #35454d;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  z-index: 1;
  max-width: 52.1rem;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 5px 5px 0;
}
.selection-reason-inner-container {
  max-height: 170px;
  overflow-y: scroll;
}
.selection-reason-inner-container::-webkit-scrollbar-track {
  border-radius: 10px;
  right: 8px;
}
.selection-reason-inner-container::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #5C7784;
}
.selection-reason-inner-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.selection-reason-inner-container::-webkit-scrollbar{
  width: 9px;
  height: 9px;
}
.other-reason-container {
  margin-top: 24px;
}
.selection-reason {
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}
.selection-reason.selected {
  background: var(--color-grey-40);
}
.dropdown-select-arrow {
  transition: all 0.2s;
}
.dropdown-select-arrow.rotate {
  transform: rotate(180deg);
}
/* .perfect-scrollball__medium {
  max-height: 10.625rem;
} */
.form-group__textarea-container {
  margin-top: 3px;
}
.textarea {
  min-height: 7.5rem;
  max-height: 7.5rem;
  max-width: 52.1rem;
  width: 52.1rem;
  resize: none;
}
@media (max-width: 768px) {
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
  }
  .instruction {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
  ol {
    padding-left: 20px;
  }
  ul {
    padding: 16px 0 16px 30px;
  }
  ul li::marker {
    content: initial;
  }
  .subtitle {
    font-weight: 700;
    line-height: 13px;
    font-size: 11px;
  }
}
</style>
